import * as React from 'react'
import styled from 'styled-components'
import * as constants from 'static/constants'
import SVG from 'react-inlinesvg'

interface IProps {
  show: boolean
  handleClose: () => void
  position?: 'right' | 'left'
  width?: 'lg' | 'sm'
  height?: string
  backItemClickable?: boolean
  showCloseIcon?: boolean
}

const Drawer: React.FC<IProps> = ({
  show,
  handleClose,
  position = 'right',
  width = 'lg',
  height = '100dvh',
  backItemClickable = false,
  showCloseIcon = false,
  children,
}) => {
  return (
    <>
      {show && (
        <Wrapper
          onClick={e => e.target === e.currentTarget && handleClose()}
          position={position}
          width={width}
          height={height}
          backItemClickable={backItemClickable}
          className={width === 'sm' && 'sm'}
        >
          <div className="Inner">
            {showCloseIcon && (
              <span className="Close" onClick={handleClose} role="button" aria-label="Close Drawer">
                {width === 'lg' ? (
                  <SVG
                    src="/images/icons/close.svg"
                    className="icon"
                    aria-hidden="true"
                    role="presentation"
                  />
                ) : (
                  <SVG
                    src="/images/icons/keyboard_double_arrow_right.svg"
                    className="icon"
                    aria-hidden="true"
                    role="presentation"
                  />
                )}
              </span>
            )}
            {children}
          </div>
        </Wrapper>
      )}
    </>
  )
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: ${({ position }) => (position === 'right' ? 'end' : 'start')};
  letter-spacing: 0;
  transition: opacity 0.4s;
  transform: translate3d(0, 100%, 0);
  transform: none;
  z-index: 6000;
  pointer-events: ${({ backItemClickable }) => (backItemClickable ? 'none' : 'auto')};
  &.sm {
    top: calc(57px + 4px);
    .Inner {
      height: calc(100dvh - (57px + 4px));
      border: 1px solid ${constants.COLORS.divider};
      border-right: 0;
      border-radius: 12px 0 0 0;
      .Close {
        top: 12px;
        color: ${constants.COLORS.subText};
      }
    }
  }

  .Inner {
    position: relative;
    // width: ${({ width }) => width};
    width: ${({ width }) => (width === 'lg' ? '60%' : 'calc(30% - 8px)')};
    max-width: 100%;
    height: ${({ height }) => height};

    background: ${constants.COLORS.white};
    box-shadow: -1px 0 12px rgba(0, 0, 0, 0.1);

    @media (max-width: ${constants.BREAKPOINT_DESKTOP_XL}px) {
      width: ${({ width }) => (width === 'lg' ? '70%' : 'calc(35% - 8px)')};
    }

    @media (max-width: ${constants.BREAKPOINT_DESKTOP_LARGE}px) {
      width: ${({ width }) => (width === 'lg' ? '80%' : 'calc(40% - 8px)')};
    }
    
    @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
      width: 100%;
      height: calc(100% - 100px);
      height: 100dvh;
      max-height: none;
      margin: 0;
      border: none;
      border-radius: 0;
    }

    .Close {
      position: absolute;
      top: 16px;
      right: 16px;
      width: 32px;
      height: 32px;
      padding: 4px;
      border-radius: 50%;
      cursor: pointer;
      &:hover {
        background-color: ${constants.COLORS.cardBG};
      }
      pointer-events: auto;

      @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
        top: 12px;
        left: 12px;
      }
    }

    animation: ${({ position }) => (position === 'right' ? 'slideinRight' : 'slideinLeft')} 0.2s
      forwards;

    @keyframes slideinRight {
      0% {
        transform: translateX(100%);
      }
      100% {
        transform: translateX(0);
      }
    }
    @keyframes slideinLeft {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(0);
      }
    }
  }
`

export default Drawer
