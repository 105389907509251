import * as React from 'react'
import styled from 'styled-components'

interface IProps {
  children: React.ReactNode
  type?: 'space-between' | 'no-shrink'
  wrap?: boolean
  alignItems?: string
}
const FlexItem: React.VFC<IProps> = ({ children, type, wrap = false, alignItems }) => {
  return (
    <FlexItemWrapper
      className={type}
      style={{ flexWrap: wrap ? 'wrap' : 'nowrap' }}
      alignItems={alignItems}
    >
      {children}
    </FlexItemWrapper>
  )
}

const FlexItemWrapper = styled.div<{ alignItems: string }>`
  display: flex;
  align-items: ${props => props.alignItems || 'center'};
  gap: 8px;
  padding: 2px 0;
  &.space-between {
    justify-content: space-between;
  }
  &.no-shrink {
    flex-shrink: 0;
  }
`
export default FlexItem
